<template>
    <div>
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Действия сотрудников за период</h2>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="processedData"
            class="report-table fixed-table"
            :footerProps="{ 'items-per-page-options': [10, 15, 50, 100] }"
        >
            <template slot="body.append">
                <tr v-for="item in usersSummary" class="summary-row">
                    <td
                        v-for="header in headers"
                        :class="{ 'text-right': header.value !== 'userName' }"
                        :key="header.value"
                    >
                        {{ item[header.value] }}
                    </td>
                </tr>
                <tr class="summary-row">
                    <td>ОБЩИЙ ИТОГ</td>
                    <td
                        class="text-right"
                        v-for="header in headers.filter(h => h.value !== 'userName')"
                        :key="header.value"
                    >
                        {{ summary[header.value] }}
                    </td>
                </tr>
            </template>
            <template v-slot:[`item.tags`]="{ item }">
                <v-tooltip right v-if="item.tags && item.tags.length">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="communication-tags">
                            <div v-for="tagId in item.tags" :key="tagId" class="communication-tag">
                                {{ getTagName(tagId) }}
                            </div>
                        </div>
                    </template>
                    <span>{{ getTagsTooltipText(item.tags) }}</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { sumBy, groupBy, first } from 'lodash'
import { numberWithSpaces, numSort, alphabetSort } from '@/utils'
import { mapStores } from 'pinia'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'

export default {
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        headers: [
            { text: 'Сотрудник', value: 'userName', width: '220px', sort: alphabetSort },
            { text: 'Дата действия', value: 'actionDate', align: 'right' },
            {
                text: 'Тип действия',
                value: 'actionName',
                align: 'right',
                width: '130px',
                sort: alphabetSort,
            },
            {
                text: 'Клиент',
                value: 'clientName',
                align: 'right',
            },
            {
                text: 'Способ связи',
                value: 'communicationType',
                align: 'right',
            },
            {
                text: 'Комментарий ',
                value: 'comment',
                align: 'right',
            },
            {
                text: 'Теги ',
                value: 'tags',
                align: 'left',
            },
            {
                text: 'Премия',
                value: 'fixed',
                align: 'right',
                sort: numSort,
            },
        ],
    }),
    computed: {
        ...mapStores(useGcb2Store),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        communicactionTags() {
            return this.gcb2Store.getAllCommunicationTags(this.project.id)
        },
        summary() {
            return {
                fixed: numberWithSpaces(Math.round(sumBy(this.chartData, el => Number(el.fixed)))) || 0,
            }
        },
        usersSummary() {
            const grouped = groupBy(this.chartData, 'user_id')
            return Object.keys(grouped).map(userId => ({
                userId,
                userName: first(grouped[userId])?.user_name,
                fixed: numberWithSpaces(Math.round(sumBy(grouped[userId], el => Number(el.fixed)))) || 0,
            }))
        },
        processedData() {
            console.log(this.chartData)
            return this.chartData.map(el => ({
                userName: el.user_name,
                actionDate: el.action_date,
                actionName: el.action_name + ' ' + el.activation_name,
                clientName: el.client_name,
                communicationType: this.getCommunicationTypeText(el.comm_type),
                comment: el.action_comment,
                tags: el.tags,
                fixed: numberWithSpaces(Number(Math.round(el.fixed * 100) / 100)) || 0,
            }))
        },
    },
    methods: {
        getCommunicationTypeText(communicationType) {
            if (communicationType === 'phone') return 'Звонок'
            if (communicationType === 'mess') return 'Сообщение'
            if (communicationType === 'no') return 'Нет информации'
        },
        getTagName(tagId) {
            return this.communicactionTags.find(el => el.id === tagId)?.name ?? ''
        },
        getTagsTooltipText(tagsIds) {
            return `ТЕГИ:\n${tagsIds.map(id => this.getTagName(id)).join('\n')}`
        },
    },
    created() {},
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
::v-deep .fixed-table table
    table-layout: fixed
    word-break: break-word
::v-deep .fixed-table table th
    padding: 0 12px !important
::v-deep .fixed-table table td
    padding: 0 12px !important

.communication-tag
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    width: 100px
</style>
