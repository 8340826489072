<template>
    <div>
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Факты записей за период</h2>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="processedData"
            class="report-table fixed-table"
            :footerProps="{ 'items-per-page-options': [10, 15, 50, 100] }"
        >
            <template v-slot:[`item.communication`]="{ item }">
                <div style="display: flex; flex-direction: column">
                    <div>
                        <span style="display: block">
                            {{ item.communication.userName }}
                        </span>
                    </div>
                    <div>
                        <span style="display: block">
                            {{ item.communication.date }}
                        </span>
                    </div>
                    <div>
                        <span style="display: block">
                            {{ item.communication.activationName }}
                        </span>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.attСreatedAt`]="{ item }">
                <span>{{ `${item.attСreatedAt.createdAt} (в течение ${item.attСreatedAt.delay} дн.)` }}</span>
            </template>
            <template slot="body.append">
                <tr v-for="item in usersSummary" class="summary-row">
                    <td v-for="header in headers" class="text-right" :key="header.value">
                        {{ item[header.value] }}
                    </td>
                </tr>
                <tr class="summary-row">
                    <td>ОБЩИЙ ИТОГ</td>
                    <td
                        class="text-right"
                        v-for="header in headers.filter(h => h.value !== 'clientName')"
                        :key="header.value"
                    >
                        {{ summary[header.value] }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { sumBy, groupBy, first } from 'lodash'
import { numberWithSpaces, numSort, alphabetSort } from '@/utils'
import moment from 'moment'
const communicationSort = (a, b) => {
    return moment(a.date).unix() - moment(b.date).unix()
}
const createdAtSort = (a, b) => {
    return moment(a.createdAt).unix() - moment(b.createdAt).unix()
}

export default {
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        headers: [
            { text: 'Клиент', value: 'clientName', width: '220px' },
            { text: 'Дата создания записи', value: 'attСreatedAt', align: 'right', sort: createdAtSort },
            {
                text: 'Коммуникация',
                value: 'communication',
                align: 'right',
                sort: communicationSort,
            },
            {
                text: 'Премия',
                value: 'fixed',
                align: 'right',
                sort: numSort,
            },
        ],
    }),
    watch: {},
    computed: {
        summary() {
            return {
                fixed: numberWithSpaces(Math.round(sumBy(this.chartData, el => Number(el.fixed)))) || 0,
            }
        },
        usersSummary() {
            const grouped = groupBy(this.chartData, 'user_name')
            return Object.keys(grouped).map(userName => ({
                communication: userName,
                fixed: numberWithSpaces(Math.round(sumBy(grouped[userName], el => Number(el.fixed)))) || 0,
            }))
        },
        processedData() {
            return this.chartData.map(el => ({
                clientName: el.client_name,
                attСreatedAt: { createdAt: el.att_created_at, delay: el.delay_from_communication },
                communication: { userName: el.user_name, date: el.communicated_at, activationName: el.activation_name },
                fixed: numberWithSpaces(Number(Math.round(el.fixed * 100) / 100)) || 0,
            }))
        },
    },
    methods: {},
    created() {},
}
</script>
<style lang="sass" scoped>
::v-deep .fixed-table table
    table-layout: fixed
    word-break: break-all
::v-deep .fixed-table table th
    padding: 0 12px !important
::v-deep .fixed-table table td
    padding: 0 12px !important
</style>
