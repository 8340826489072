<template>
    <div>
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Состоявшиеся посещения за период</h2>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="processedData"
            class="report-table fixed-table"
            :footerProps="{ 'items-per-page-options': [10, 15, 50, 100] }"
        >
            <template v-slot:[`item.communication`]="{ item }">
                <div style="display: flex; flex-direction: column">
                    <div>
                        <span style="display: block">
                            {{ item.communication.userName }}
                        </span>
                    </div>
                    <div>
                        <span style="display: block">
                            {{ item.communication.date }}
                        </span>
                    </div>
                    <div>
                        <span style="display: block">
                            {{ item.communication.activationName }}
                        </span>
                    </div>
                </div>
            </template>
            <template slot="body.append">
                <tr v-for="item in usersSummary" class="summary-row">
                    <td
                        v-for="header in headers"
                        :class="{ 'text-right': header.value !== 'userName' }"
                        :key="header.value"
                    >
                        {{ item[header.value] }}
                    </td>
                </tr>
                <tr class="summary-row">
                    <td>ОБЩИЙ ИТОГ</td>
                    <td
                        class="text-right"
                        v-for="header in headers.filter(h => h.value !== 'clientName')"
                        :key="header.value"
                    >
                        {{ summary[header.value] }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { sumBy, groupBy, first } from 'lodash'
import { numberWithSpaces, numSort, alphabetSort } from '@/utils'
import moment from 'moment'

const communicationSort = (a, b) => {
    return moment(a.date).unix() - moment(b.date).unix()
}
export default {
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        headers: [
            { text: 'Клиент', value: 'clientName', width: '160px', sort: alphabetSort },
            { text: 'Дата посещения', value: 'visitDate', align: 'right' },
            {
                text: 'Список услуг',
                value: 'services',
                align: 'right',
            },
            {
                text: 'Выручка',
                value: 'revenue',
                align: 'right',
                sort: numSort,
            },
            {
                text: 'Коммуникация',
                value: 'communication',
                align: 'right',
                sort: communicationSort,
            },
            {
                text: 'Премия',
                value: 'bonus',
                align: 'right',
                sort: numSort,
            },
        ],
    }),
    computed: {
        summary() {
            return {
                bonus: numberWithSpaces(Math.round(sumBy(this.chartData, el => Number(el.bonus)))) || 0,
            }
        },
        usersSummary() {
            const grouped = groupBy(this.chartData, 'user_id')
            return Object.keys(grouped).map(userId => ({
                userId,
                communication: first(grouped[userId])?.user_name,
                bonus: numberWithSpaces(Math.round(sumBy(grouped[userId], el => Number(el.bonus)))) || 0,
            }))
        },
        processedData() {
            return this.chartData.map(el => ({
                clientName: el.client_name,
                visitDate: el.visit_date,
                services: el.service_name_list,
                revenue: numberWithSpaces(Number(Math.round(el.revenue * 100) / 100)) || 0,
                bonus: numberWithSpaces(Number(Math.round(el.bonus * 100) / 100)) || 0,
                communication: { userName: el.user_name, date: el.communicated_at, activationName: el.activation_name },
            }))
        },
    },
    methods: {},
    created() {},
}
</script>
<style lang="sass" scoped>
::v-deep .fixed-table table
    table-layout: fixed
    word-break: break-all
::v-deep .fixed-table table th
    padding: 0 12px !important
::v-deep .fixed-table table td
    padding: 0 12px !important
</style>
