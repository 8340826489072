
import Vue from 'vue'
import { Connector, Project, ProjectType } from '@/types/main'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'
import _ from 'lodash'

import { CONNECTOR_TYPES } from '../../../vars/general'
import BaseInputOutlined from '../../../components/inputs/BaseInputOutlined.vue'
import moment, { Moment } from 'moment'
import Select from '../../../components/inputs/Select.vue'
import { getKpiReportData, getKpiReportFilters, getKpiSchemeIdFilterValues } from '../../../api/chisai/GCB2'
import StaffBonusTable from '../../../components/chisai/GCB2/kpi/StaffBonusTable.vue'
import StaffActionsTable from '../../../components/chisai/GCB2/kpi/StaffActionsTable.vue'
import AttendanceTable from '../../../components/chisai/GCB2/kpi/AttendanceTable.vue'
import RecordsTable from '../../../components/chisai/GCB2/kpi/RecordsTable.vue'
import CustomActivatorSelect from '../../../components/inputs/CustomActivatorSelect.vue'

import api from '../../../api/api'
import { mapFilterToObjectFormat } from '../../../utils'
import { mapStores } from 'pinia'
import { useGcb2Store } from '../../../store/stores/gcb2/gcb2'
export default Vue.extend({
    components: {
        SpinnerLoader,
        BaseInputOutlined,
        CustomActivatorSelect,
        Select,
        StaffBonusTable,
        StaffActionsTable,
        AttendanceTable,
        RecordsTable,
    },

    data: () => ({
        loading: true as boolean,
        dates: [] as string[],
        dashboardData: {} as any,
        dateFormat: 'DD.MM.YYYY',
        projectType: null as ProjectType | null,
        dateMenu: false,
        filtersData: {
            user_name_list: [],
            activation_name_list: [],
            tag_list: [],
            typeOfCommList: [],
            filial_list_att: [],
            filial_list_comm: [],
            kpiSettingsId: {},
            filialList: [],
        },
        filtersModel: {
            user_name_list: [],
            activation_name_list: [],
            tag_list: [],
            typeOfCommList: [],
            filial_list_att: [],
            filial_list_comm: [],
            delayFromCommunication: '30',
            productsDisplay: 1,
            kpiSettingsId: null,
            sameFilial: false,
        },
        acticationFilter: {},
        productDisplayFilterValues: [
            { id: 1, name: 'Только услуги' },
            { id: 2, name: 'Услуги и товары, купленные с услугами' },
            { id: 3, name: 'Все услуги и товары' },
        ],
        communicactionChannelFilterValues: [
            { id: 'phone', name: 'Звонок' },
            { id: 'mess', name: 'Сообщение' },
            { id: 'no', name: 'Нет информации о типе связи' },
        ],
        communicationTags: [],
        numRule: (v: any) => {
            if (!v.trim()) return true
            if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) return true
            return 'Должно быть числом'
        },
    }),
    computed: {
        ...mapStores(useGcb2Store),
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        sameFilialFilterText(): string {
            return this.projectIsNetwork
                ? 'Учитывать записи и посещения только в филиал коммуникации'
                : 'Учитывать записи и посещения только в данный филиал'
        },
        projectIsNetwork(): boolean {
            return this.projectType === 'network'
        },
        projectIsBranch(): boolean {
            return this.projectType === 'branch'
        },
        GCB2Connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        dateRangeText(): string {
            this.sortDates()
            const orderedDates = this.dates
            const formatedDates = orderedDates.map(date => moment(date).format(this.dateFormat))
            return formatedDates.join(' - ')
        },
    },
    watch: {
        'filtersModel.delayFromCommunication'(v) {
            if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) {
                localStorage.setItem('kpi:filtersModel.delayFromCommunication', v)
            }
        },
        dateMenu(val) {
            if (!val && this.dates.length === 1) {
                this.dates.push(this.dates[0])
            }
            if (!val) {
                this.initFilters()
            }

        },
    },
    methods: {
        sortDates() {
            if (this.dates.length === 1) {
                return moment(this.dates[0])
            }
            const date1 = moment(this.dates[0])
            const date2 = moment(this.dates[1])
            if (date1 < date2) {
                this.dates[0] = date1.format('YYYY-MM-DD')
                this.dates[1] = date2.format('YYYY-MM-DD')
            } else if (date1 > date2) {
                this.dates[0] = date2.format('YYYY-MM-DD')
                this.dates[1] = date1.format('YYYY-MM-DD')
            } else {
                return date2
            }
        },
        async initFilters(firstLoad?: boolean) {
            this.filtersData.user_name_list = []
            this.filtersData.activation_name_list = []
            this.filtersData.tag_list = []
            this.filtersData.typeOfCommList = []
            this.filtersData.filial_list_att = []
            this.filtersData.filial_list_comm = []

            const { data, error } = await getKpiReportFilters({
                projectId: this.project.id,
                filtersDate: {
                    dateFrom: this.dates[0],
                    dateTo: this.dates[1],
                },
            })

            if (error) {
                this.$store.dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }

            const filters = data.filters

            // Вычленяем все ключи из data.filters и ключи на уровень ниже
            // Затем мы делаем им необходимый вид, чтобы пользоваться ими дальше
            Object.keys(filters).forEach(el => {
                // Проверяем, есть ли у нас массив данных в filters[el]
                if (Array.isArray(filters[el]) && filters[el].length) {
                    this.filtersData[el] = this.filtersData[el] || [] // Инициализация массива
                    for (const i in filters[el]) {
                        this.filtersData[el].push({ name: filters[el][i], id: filters[el][i] })
                    }
                }
                // Проверяем, является ли элемент 'tag_list' и не является ли он undefined или null
                else if (el === 'tag_list' && filters[el] && Object.keys(filters[el]).length) {
                    this.filtersData[el] = this.filtersData[el] || [] // Инициализация массива
                    Object.keys(filters[el]).forEach(el2 => {
                        console.log('filters[el]', filters[el])
                        const clearTagId = el2.replace('["', '').replace('"]', '')
                        this.filtersData[el].push({ name: clearTagId, id: clearTagId })
                    })
                }
                // Обрабатываем другие случаи
                else {
                    if (filters[el] && Object.keys(filters[el]).length) {
                        this.filtersData[el] = this.filtersData[el] || [] // Инициализация массива
                        Object.keys(filters[el]).forEach(el2 => {
                            this.filtersData[el].push({ name: el2, id: el2 })
                        })
                    }
                }
            })
            // Тут мы получаем еще некоторые данный, которые после первой загрузки не будут меняться
            if (firstLoad) {
                const responses = await Promise.all([
                    getKpiSchemeIdFilterValues({
                        projectId: this.project.id,
                    }),
                    api.project.getNetworkProjects({ projectId: this.project.id }),
                ])
                this.filtersData.kpiSettingsId = responses[0].data
                this.filtersModel.kpiSettingsId = this.filtersData.kpiSettingsId[0].id
                this.filtersData.filialList = responses[1].data
                this.filtersData.filial_list_comm = this.projectIsNetwork ? this.filtersData.filialList.map((el: any) => el.id) : undefined,
                this.filtersData.filial_list_att = this.projectIsNetwork ? this.filtersData.filialList.map((el: any) => el.id) : undefined
            }
        },

        async setInitialData(firstLoad?: boolean) {
            // Эта функция берет данные из фильтров и делает запрос на получение данных
            //  При первом запросе, когда фильтры не выбраны, она делет запрос по всем данным
            // Или если после первой загрузки, какой либо из фильтров не выбран, будет так же принудительно сделан поиск по всем опциям конкретного фильтра
            if (this.dateMenu) {
                this.dateMenu = false
            }
            this.loading = true
            let cloneUsers = {}
            let cloneActivations = {}
            let cloneCommType = {}

            if (firstLoad || !this.filtersModel.user_name_list.length) {
                cloneUsers = this.filtersData.user_name_list.reduce((acc, v) => {
                    return Object.assign(acc, {
                        [v.id]: {
                            option: v.id,
                            selected: true,
                        },
                    })
                }, {})
            }
            if (firstLoad || !this.filtersModel.activation_name_list.length) {
                cloneActivations = this.filtersData.activation_name_list.reduce((acc, v) => {
                    return Object.assign(acc, {
                        [v.id]: {
                            option: v.id,
                            selected: true,
                        },
                    })
                }, {})
            }
            if (firstLoad || !this.filtersModel.typeOfCommList.length) {
                cloneCommType = this.filtersData.typeOfCommList.reduce((acc, v) => {
                    return Object.assign(acc, {
                        [v.id]: {
                            option: v.id,
                            selected: true,
                        },
                    })
                }, {})
            }

            const { data, error } = await getKpiReportData({
                projectId: this.project.id,
                filters: {
                    dateFrom: moment(this.dates[0]).format('YYYY-MM-DD'),
                    dateTo: moment(this.dates[1]).format('YYYY-MM-DD'),
                    kpiSettingsId: this.filtersModel.kpiSettingsId,
                    productsDisplay: this.filtersModel.productsDisplay,
                    filialListComm:
                        this.projectIsNetwork && (firstLoad || !this.filtersModel.filial_list_comm.length)
                            ? this.filtersData.filialList.map(el => el.id)
                            : this.projectIsNetwork
                            ? this.filtersModel.filial_list_comm
                            : undefined,
                    filialListAtt:
                        this.projectIsNetwork && (firstLoad || !this.filtersModel.filial_list_att.length)
                            ? this.filtersData.filialList.map(el => el.id)
                            : this.projectIsNetwork
                            ? this.filtersModel.filial_list_att
                            : undefined,
                    sameFilial: this.filtersModel.sameFilial,
                    user_name_list:
                        firstLoad || !this.filtersModel.user_name_list.length
                            ? cloneUsers
                            : mapFilterToObjectFormat(
                                  this.filtersModel.user_name_list,
                                  this.filtersData.user_name_list
                              ),
                    activation_name_list:
                        firstLoad || !this.filtersModel.activation_name_list.length
                            ? cloneActivations
                            : mapFilterToObjectFormat(
                                  this.filtersModel.activation_name_list,
                                  this.filtersData.activation_name_list
                              ),
                    delayFromCommunication: Number(this.filtersModel.delayFromCommunication),
                    tagList:
                        firstLoad || !this.filtersModel.tag_list.length
                            ? {}
                            : mapFilterToObjectFormat(this.filtersModel.tag_list, this.filtersData.tag_list),
                    typeOfCommList:
                        firstLoad || !this.filtersModel.typeOfCommList.length
                            ? cloneCommType
                            : mapFilterToObjectFormat(
                                  this.filtersModel.typeOfCommList,
                                  this.communicactionChannelFilterValues
                              ),
                },
            })
            if (error) {
                this.$store.dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            this.dashboardData = data
            this.loading = false
        },
        setDefaultDates() {
            const dateFormat = 'YYYY-MM-DD'
            const today = moment()
            const endDate = today.clone()
            const startDate = today.clone().subtract(7, 'days')
            this.dates = [startDate.format(dateFormat), endDate.format(dateFormat)]
        },
        async resetFiltersAndData() {
            this.loading = true
            this.filtersModel.user_name_list = []
            this.filtersModel.activation_name_list = []
            this.filtersModel.tag_list = []
            this.filtersModel.typeOfCommList = []
            this.filtersModel.filial_list_att = []
            this.filtersModel.filial_list_comm = []
            this.filtersModel.delayFromCommunication = '30'
            this.filtersModel.productsDisplay = 1
            this.filtersModel.sameFilial = false

            this.setDefaultDates()
            await this.initFilters(false)
            await this.setInitialData(false)
        },
    },
    async created() {
        const [projectType] = await Promise.all([
            api.project.getType({ projectId: this.project.id }).then(res => res.data?.type!),
            // Данные теги нам нужны, для более корректного вида на UI
            // Так как те данные, которые мы получаем в initFilters дают нам только id тегов без имени
            this.gcb2Store.fetchCommunicationTags(this.project.id),
        ])
        this.projectType = projectType
        this.communicationTags = this.gcb2Store.communicationTags
        this.setDefaultDates()
        await this.initFilters(true)
        await this.setInitialData(true)
    },
    mounted() {},
})
