
import Vue, { PropType } from 'vue'
import { Connector, Project } from '@/types/main'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../../../vars/general'
import Select from '../../../components/inputs/Select.vue'
import TextField from '../../../components/inputs/TextField.vue'
import { getKpiSettings, kpiCopySetting, kpiDeleteSetting, kpiEditSetting } from '../../../api/chisai/GCB2'

import _ from 'lodash'
import KpiSettingsActionsMenu from '../../../components/chisai/GCB2/kpi/KpiSettingsActionsMenu.vue'

export default Vue.extend({
    components: {
        SpinnerLoader,
        TextField,
        Select,
        KpiSettingsActionsMenu,
    },

    data: () => ({
        loading: true as boolean,
        kpiSettings: [] as any[] | null,
        headers: [
            { text: 'Наименование схемы', value: 'name', sortable: false },
            { text: 'Применяется по-умолчанию', value: 'is_default', sortable: false },
            { text: '', value: 'actions', sortable: false, align: 'right' },
        ] as any[],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        GCB2Connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        orderedKpiSettings(): any[] {
            return _.sortBy(this.kpiSettings, 'id')
        },
    },
    watch: {},
    methods: {
        rowClick(e: any) {
            this.editSetting({ item: e })
        },
        async changeIsDefault({ item, value }: { item: any; value: boolean }) {
            this.$store.commit('startLoading')
            const { data, error } = await kpiEditSetting({
                id: item.id,
                projectId: this.project.id,
                settings: { is_default: value },
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
                this.$store.commit('endLoading')
                return
            }
            this.kpiSettings = this.kpiSettings
                ?.filter(el => !_.map(data, 'id').includes(el.id))
                .concat(data) as any[]

            this.$store.commit('endLoading')
        },
        async deleteSetting({ item }: { item: any }) {
            this.$store.commit('startLoading')
            const { data, error } = await kpiDeleteSetting({
                projectId: this.project.id,
                id: item.id,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
                this.$store.commit('endLoading')
                return
            }
            this.kpiSettings = this.kpiSettings?.filter(el => el.id !== item.id) as any[]
            this.$store.commit('endLoading')
        },
        async copySetting({ item }: { item: any }) {
            this.$store.commit('startLoading')
            const { data, error } = await kpiCopySetting({
                projectId: this.project.id,
                id: item.id,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
                this.$store.commit('endLoading')
                return
            }
            this.kpiSettings = this.kpiSettings!.concat(data)
            this.$store.commit('endLoading')
        },
        editSetting({ item }: { item: any }) {
            this.$router.push(`/project/${this.project.id}/GCB2/kpi/setting?mode=edit&id=${item.id}`)
        },
        addSetting() {
            this.$router.push(`/project/${this.project.id}/GCB2/kpi/setting?mode=create`)
        },
    },
    async created() {
        this.loading = true
        this.kpiSettings = (await getKpiSettings({ projectId: this.project.id })).data
        this.loading = false
    },
    mounted() {},
})
